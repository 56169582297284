/** @jsx jsx */
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useMouse, useWindowSize } from 'react-use'
import { jsx } from 'theme-ui'
import {
  Box,
  ButtonInternalLink,
  Container,
  Flex,
  Heading,
  SEO,
  Txt,
  List,
  ListItem,
} from '../../components'
import {
  PokerChip,
  PokerChipValue,
  pokerChipValues,
  UnassignedPokerChip,
  pokerChipValueToBgColorMap,
} from '../../components/PokerChip'
import { urls } from '../../utils/urls'
import { appConfig } from '../../appConfig'
import { AppPageProps } from '../../types'
import { Footer } from '../../components/Footer'
import { FullHeightPage } from '../../components/FullHeightPage'
// @ts-ignore
import chipsImage from '../../images/logo/allChips.svg'

const RandomChip: React.SFC<{
  amount: PokerChipValue
  count: number
}> = ({ amount, count, ...props }) => {
  return (
    <Box
      css={{
        position: 'relative',
        display: 'inline-block',
        width: 30,
        height: 62,
      }}
      {...props}
    >
      {_.times(count).map((_, chipIndex) => (
        <UnassignedPokerChip
          formattedNumber="$"
          backgroundColor={pokerChipValueToBgColorMap[amount]}
          key={chipIndex}
          height={30}
          css={{
            position: 'absolute',
            top: (10 - chipIndex - 0) * 3,
          }}
        />
      ))}
    </Box>
  )
}

const ChipsStacking: React.SFC<{}> = () => {
  const ref = React.useRef(appConfig.IS_BROWSER ? window.document.body : null)
  const { docX } = useMouse(ref)
  const { width } = useWindowSize()
  const [counts, setCounts] = useState(_.times(8).map((count) => count))
  useEffect(() => {
    const percentage = docX / width
    setCounts(
      counts.map((_, index) => {
        const countPercentage = (index + 1) / 8
        return Math.floor(14 - 13 * Math.abs(percentage - countPercentage))
      }),
    )
  }, [docX])
  return (
    <Box>
      {pokerChipValues.slice(0, 8).map((val, index) => (
        <RandomChip count={counts[index]} amount={val} key={val} sx={{ ml: index ? 3 : 0 }} />
      ))}
    </Box>
  )
}

export const Home: React.FC<AppPageProps> = () => {
  return (
    <FullHeightPage.Container>
      <SEO title="The easy, free way to play online poker with your friends" />
      <FullHeightPage.Content>
        <Box
          sx={{
            bg: 'darkBlue',
            color: 'white',
            clipPath: 'polygon(0 0, 5760px 0, 5760px calc(100% - 422px), 0 100%)',
            pt: 6,
            pb: [5, null, null, null, 6],
          }}
        >
          <Container sx={{ textAlign: 'center' }}>
            <Box sx={{ textAlign: 'center' }}>
              <img css={{ width: 200 }} src={chipsImage} alt="OurPokerGame logo" />
            </Box>
            <Heading sx={{ fontWeight: '600' }}>
              Our
              <Txt as="span" sx={{ color: 'lightGreen' }}>
                PokerGame
              </Txt>
            </Heading>
            <Heading as="h2" sx={{ mx: 'auto', mb: 4, fontSize: [3, 3, 3, null, null, 7] }}>
              The easiest way to play online poker with your friends
            </Heading>
            <Heading as="h3">
              <List
                sx={{
                  textAlign: 'left',
                  display: 'inline-block',
                  listStyle: 'none',
                  fontSize: 4,
                  '> li::before': {
                    fontSize: 6,
                    lineHeight: 0,
                    content: `"\\2022"`,
                    color: 'lightGreen',
                    fontWeight: `bold`,
                    display: `inline-block`,
                    width: `1em`,
                    marginLeft: `-1em`,
                    position: 'relative',
                    top: '2px',
                  },
                }}
              >
                <ListItem sx={{ mt: 3 }}>Set up a game in seconds</ListItem>
                <ListItem sx={{ mt: 3 }}>Use mobile or desktop</ListItem>
                <ListItem sx={{ mt: 3 }}>
                  <Txt as="span" sx={{ color: 'lightGreen', fontWeight: 'bold' }}>
                    Free to play
                  </Txt>
                  , forever
                </ListItem>
              </List>
            </Heading>
            <Flex
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                my: 6,
                flexDirection: ['column', null, 'row'],
              }}
            >
              <ButtonInternalLink
                size="lg"
                sx={{ width: 200 }}
                block
                to={urls.newGameForm()}
                variant="lightGreen"
              >
                Create A Game
              </ButtonInternalLink>
              <Txt sx={{ fontSize: 4, mx: [4, 4, 5], my: 3 }}>or</Txt>
              <ButtonInternalLink
                size="lg"
                sx={{ width: 200, borderColor: 'white', color: 'white' }}
                block
                to={urls.demoGame()}
              >
                See a Live Demo
              </ButtonInternalLink>
            </Flex>
          </Container>
        </Box>
        <Box sx={{ textAlign: 'center', mt: 3 }}>
          <ChipsStacking />
        </Box>
      </FullHeightPage.Content>
      <Footer />
    </FullHeightPage.Container>
  )
}
