/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { css } from 'theme-ui'
import { FormattedNumber } from './FormattedNumber'
import { Box, Txt } from './'
import { theme } from '../gatsby-plugin-theme-ui/theme'
import { darken, lighten } from 'polished'

export type PokerChipValue = 1 | 5 | 10 | 25 | 50 | 100 | 250 | 500 | 1000 | 5000
export const pokerChipValues: PokerChipValue[] = [1, 5, 10, 25, 50, 100, 250, 500, 1000, 5000]

export const pokerChipValueToBgColorMap: Record<PokerChipValue, string> = {
  1: 'gray.3',
  5: 'red',
  10: 'blue',
  25: 'green',
  50: 'orange',
  100: 'gray.7',
  250: 'pink',
  500: 'purple',
  1000: 'yellow',
  5000: 'brown',
}

export const UnassignedPokerChip: React.SFC<{
  backgroundColor: string
  formattedNumber: React.ReactNode
  height: number
}> = ({ backgroundColor, formattedNumber, height, ...props }) => {
  return (
    <Box
      sx={{
        fontSize: `${height}px`,
        width: height,
        height,
        borderRadius: 'pill',
        backgroundColor,
        boxShadow: '0px 0px 1px 1px rgba(0,0,0,0.75)',
        border: `2px dashed rgba(0,0,0,.2)`,
        boxSizing: 'border-box',
      }}
      {...props}
    >
      <Txt
        sx={{
          width: height - 6,
          height: height - 6,
          margin: '1px',
          fontSize: '.4em',
          color: 'rgba(255,255,255,.8)',
          // textShadow: `0px 0px 2px rgba(0,0,0,1), 1px 1px 6px rgba(0,0,0,1)`,
          borderRadius: 'pill',
          border: `1px solid rgba(0,0,0,.3)`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: 'inset 6px 0px 30px 30px rgba(0,0,0,0.15)',
        }}
      >
        {formattedNumber}
      </Txt>
    </Box>
  )
}

export const PokerChip: React.SFC<{ value: PokerChipValue; height?: number }> = ({
  value,
  height = 30,
  ...props
}) => {
  const backgroundColor = pokerChipValueToBgColorMap[value]
  return (
    <UnassignedPokerChip
      {...{
        ...props,
        backgroundColor,
        height,
        formattedNumber: <FormattedNumber format="shortest-abbreviation" amount={value} />,
      }}
    />
  )
}
