/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { ForwardRef } from '../types'

export const Container: ForwardRef<
  HTMLDivElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = React.forwardRef((props, ref) => (
  <div sx={{ minHeight: '100vh', flexDirection: 'column', display: 'flex' }} ref={ref} {...props} />
))

export const Content: ForwardRef<
  HTMLDivElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = React.forwardRef((props, ref) => (
  <div sx={{ flex: '1 0 auto', flexDirection: 'column', display: 'flex' }} ref={ref} {...props} />
))

export const FullHeightPage = {
  Container,
  Content,
}
