/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import React from 'react'
import { ForwardRef } from '../types'
import { InternalLink, Txt, ExternalLink } from '.'
import { urls } from '../utils/urls'

export const Footer: React.FC<{}> = () => {
  return (
    <footer sx={{ py: 5, px: 3, textAlign: 'center' }}>
      {[
        <ExternalLink href={urls.mailToSupport()}>support@ourpokergame.com</ExternalLink>,
        <InternalLink to={urls.home()}>Home</InternalLink>,
        // <InternalLink to={urls.privacyPolicy()}>Privacy Policy</InternalLink>,
        // <InternalLink to={urls.termsAndConditions()}>Terms And Conditions</InternalLink>,
      ].map((content, index) => (
        <Txt
          sx={{
            display: 'inline-block',
            ml: index ? [4, null, null, 6] : 0,
            fontSize: ['10px', 0, null, null, 1],
          }}
          key={index}
        >
          {content}
        </Txt>
      ))}
    </footer>
  )
}
